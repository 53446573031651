import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import App from "./App";import ReactGA from "react-ga"
ReactGA.initialize([
    {trackingId: "UA-20726712-1"},
    {trackingId: "GTM-M2RS4PT"}
]);

const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(
        <HashRouter onUpdate={() => ReactGA.pageview(window.location.hash)}>
            {/*<Switch>*/}
                <Component/>
            {/*</Switch>*/}
        </HashRouter>,
    rootElement
);
};

renderApp(App);
